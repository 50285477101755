<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Shift Master" slot="title" link="/helpContent/AddUpdateShift" />
    <ValidationObserver ref="validator">
        <FormRow>
          <div class="col-sm-3">
            <InputText
              label="Shift Name"
              v-model="form.shiftName"
              maxlength="50"
              rules="required"
            />
          </div>
          <div class="col-sm-3">
            <FormSelect
              label="Shift Type"
              v-model="form.shiftType"
             :items="shiftTypeList"
             item-value="id" item-name="Name"
              rules="required"
            />
          </div>
          <div class="col-sm-1">
            <InputTime label="Start Time" v-model="form.startTime" rules="required" />
          </div>
          <div class="col-sm-1">
            <InputTime label="End Time" v-model="form.endTime" rules="required" />
          </div>
        </FormRow>
        <FormRow>
          <div class="col-sm-2">
            <InputWeek
              label="Week Off 1"
              v-model="form.weekOneOff"
              rules="required"
            />
          </div>
          <div class="error" v-if="weekOneErr"> Required. </div>
          <div class="col-sm-3">
                <input type="checkbox" value="1"  class="checkBox" v-model="weekTypeOne"><span class="check-Box-lable"> 1</span> 
                <input type="checkbox" value="2" class="checkBox" v-model="weekTypeOne"> <span class="check-Box-lable">2</span> 
                <input type="checkbox" value="3" class="checkBox" v-model="weekTypeOne"><span class="check-Box-lable"> 3</span> 
                <input type="checkbox" value="4" class="checkBox" v-model="weekTypeOne"> <span class="check-Box-lable"> 4</span> 
                <input type="checkbox" value="5" class="checkBox"  v-model="weekTypeOne"> <span class="check-Box-lable"> 5</span> 
          </div>
          <div class="error2" v-if="weekTypeOneErr"> Required. </div>
        </FormRow>
        <FormRow>
          <div class="col-sm-2">
            <InputWeek
              label="Week Off 2"
              v-model="form.weekTwoOff"
            />
          </div>
          <div class="col-sm-3">
              <input type="checkbox" value="1" class="checkBox" v-model="weekTypeTwo"><span class="check-Box-lable"> 1</span> 
              <input type="checkbox" value="2" class="checkBox" v-model="weekTypeTwo"> <span class="check-Box-lable"> 2</span> 
              <input type="checkbox" value="3" class="checkBox" v-model="weekTypeTwo"><span class="check-Box-lable"> 3</span> 
              <input type="checkbox" value="4" class="checkBox" v-model="weekTypeTwo"> <span class="check-Box-lable"> 4</span> 
              <input type="checkbox" value="5" class="checkBox" v-model="weekTypeTwo"> <span class="check-Box-lable" > 5</span> 
          </div>
        </FormRow>
        <FormRow>
          <div class="col-sm-6">
              <ButtonGroup>
                <FormButton type="info" @click="() => {$router.go(-1);}">Back</FormButton>
                <FormButton type="success" @click="sendData()">Save</FormButton>
              </ButtonGroup>
          </div>
        </FormRow>
    </ValidationObserver>
    <br />
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
      >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import loadingMixin from "Mixins/loadingMixin";
  import {getAllShiftType, addUpdateShiftMaster } from "../api";
  import { ValidationObserver } from "vee-validate";
  export default {
    name: "AddShiftMaster",
    mixins: [loadingMixin],
    components: {
      FormWrapper,
      PageTitle,
      ValidationObserver
    },
    data() {
      return {
        showMessage: {
          isVisible: false,
        },
        form: {
          shiftName: "",
          startTime:"",
          endTime:"",
          weekOneOff:0,
          weekTwoOff:0,
          shiftType: "",
          weekTwoOffType: "",
          weekOneOffType:"",
          id:0
        },
        staffList: [],
        shiftTypeList:[],
        weekTypeOne:[],
        weekTypeTwo:[],
        weekOneErr:false,
        weekTypeOneErr:false,
      };
    },
    created() {
      this.getShiftType();
    },
    methods: {
      getShiftType(){
          getAllShiftType().then(res=>{
              this.shiftTypeList=res.data
          });
      },
      sendData() {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            if (this.form.weekOneOff === 0) {
              this.weekOneErr=true;
              return;
            } else{
              this.weekOneErr=false;
            }
            if (this.weekTypeOne.length === 0) {
              this.weekTypeOneErr=true;
              return;
            } else{
              this.weekTypeOneErr=false;
            }
            if(this.weekOneErr === false && this.weekTypeOneErr === false){
              this.showMessage.isVisible= false;
              this.form.weekOneOffType= this.weekTypeOne.toString();
              this.form.weekTwoOffType= this.weekTypeTwo.toString();
              const data = {
                shiftName: this.form.shiftName,
                startTime: this.form.startTime,
                endTime: this.form.endTime,
                weekOneOff: this.form.weekOneOff,
                weekOneOffType: this.form.weekOneOffType,
                weekTwoOff: this.form.weekTwoOff,
                weekTwoOffType: this.form.weekTwoOffType,
                shiftType: this.form.shiftType,
                id:this.form.id
              };
              addUpdateShiftMaster(data).then((res) => {
                this.form = {};
                this.showMessage.isVisible = true;
                this.showMessage.message = res.data[0].message;
              });
            }
          }
        });
      },
    },
  };
</script>
<style lang="scss">
  .checkBox
  {
    width: 15px !important;
    height: 15px !important;
    margin-left: 8px;
  }

  .checkBox1{
    width: 15px !important;
    height: 15px !important;
  }

  .check-Box-lable{
    padding: 5px 5px 0px 5px;
    font-size: 15px;
    vertical-align: top;
  }

  .required {
    color: #ff3547;
    margin-right: 2px;
  }

  .error {
    color: #ff3547;
    position: absolute;
    max-width: 100%;
    left: 0;
    font-size: 11px;
    line-height: 13px;
    bottom: -15px;
    font-weight: 400;
    margin-left: 15px;
  }
  .error2 {
    color: #ff3547;
    position: absolute;
    max-width: 100%;
    left: 0;
    font-size: 11px;
    line-height: 13px;
    bottom: -15px;
    font-weight: 400;
    margin-left: 270px;
  }
</style>
